<template>
  <div class="evaluation bj">
    <!-- 表单搜索 start -->
    <div class="title">
      <div class="form jn-form-style">
        <div class="form-item btn-wrap">
          <el-button
            type="primary"
            style="margin-left: 5px"
            icon="el-icon-refresh"
            class="refresh"
            @click="Refresh"
          ></el-button>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.as_no"
            placeholder="请输入售后单号"
            clearable
          ></el-input>
        </div>

        <div class="form-item">
          <el-input
            v-model="formData.order_no"
            placeholder="请输入订单号"
            clearable
          ></el-input>
        </div>
        <div class="form-item">
          <el-date-picker
            @change="payChange"
            v-model="pay_at"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="售后创建时间起"
            end-placeholder="售后创建时间止"
          >
          </el-date-picker>
        </div>
        <div class="form-item">
          <el-date-picker
            @change="payChange1"
            v-model="pay_at1"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="售后完成时间起"
            end-placeholder="售后完成时间止"
          >
          </el-date-picker>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.supplier_name"
            placeholder="请输入供应商"
            clearable
          ></el-input>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.business_user_nickname"
            placeholder="请输入客服账号"
            clearable
          ></el-input>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.mobile"
            placeholder="请输入客户电话"
            clearable
          ></el-input>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.fullname"
            placeholder="请输入客户姓名"
            clearable
          ></el-input>
        </div>

        <div class="form-item">
          <el-select
            v-model="formData.score"
            clearable
            placeholder="请选择客户评价"
          >
            <el-option label="差" value="2"></el-option>
            <el-option label="一般" value="3"></el-option>
            <el-option label="尚可" value="4"></el-option>
            <el-option label="满意" value="5"></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select
            v-model="formData.logistics_id"
            clearable
            filterable
            placeholder="请选择购买城市仓"
          >
            <el-option
              v-for="item of storelist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>

        <div class="form-item">
          <el-button
            @click="onHandleExport"
            type="warning"
            icon="el-icon-download"
            >导 出</el-button
          >
          <el-button
            @click="onHandleSearch"
            type="primary"
            icon="el-icon-search"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <!-- 表单搜索 end -->

    <!-- 列表 start -->
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column align="center" label="售后单号" prop="product_name">
          <template slot-scope="scope">
            {{ scope.row.aftersale.as_no }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="订单号" prop="first_cid_name">
          <template slot-scope="scope">
            {{ scope.row.aftersale.order_no }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="售后创建日期"
          prop="categories_name"
        >
          <template slot-scope="scope">
            {{ scope.row.aftersale.create_time }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="售后完成日期" prop="source">
          <template slot-scope="scope">
            {{ scope.row.aftersale.operator_at }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="客户姓名" prop="share_time">
          <template slot-scope="scope">
            {{ scope.row.member.fullname }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="客户电话"
          prop="share_member_mobile"
        >
          <template slot-scope="scope">
            {{ scope.row.member.mobile }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="城市仓" prop="share_member_name">
          <template slot-scope="scope">
            {{ scope.row.order.logistics.name }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="供应商" prop="title">
          <template slot-scope="scope">
            {{ scope.row.supplier.title }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="客户评分" prop="score">
        </el-table-column>
        <el-table-column align="center" label="客户评价内容" prop="content">
        </el-table-column>
        <el-table-column
          align="center"
          label="客服账号"
          prop="business_user_nickname"
        >
        </el-table-column>
      </el-table>
    </div>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->
  </div>
</template>
  <script>
import { formatDate } from "@/utils/tools/date.js";
export default {
  name: "evaluation",
  components: {},
  data() {
    return {
      loading: false,
      pay_at: [],
      pay_at1: [],
      formData: {
        as_no: "", //	T文本	是	售后单号
        order_no: "", //		T文本	是	订单号
        create_time: "", //		T文本	是	售后提交时间
        operator_at: "", //		T文本	是	售后完成时间
        mobile: "", //		T文本	是	客户电话
        fullname: "", //		T文本	是	客户姓名
        logistics_id: "", //		T文本	是	城市仓id
        score: "", //		T文本	是	评分
        business_user_nickname: "", //		T文本	是	客服账号
        supplier_name: "", //		T文本	是	供应商
        page: 1,
        pageSize: 50,
      }, // 表单参数
      tableData: [], // 城市仓列表
      total: 0,
      storelist: [],
    };
  },
  mounted() {
    this.onInitData();
    this.citylist();
  },
  methods: {
    citylist() {
      this.$api.general
        .logisticsList({
          name: "",
          page: 1,
          pageSize: 100000,
        })
        .then((res) => {
          console.log(res, "获取数据");
          this.storelist = res.data.data;
        });
    },
    payChange(val) {
        if (val) {
          this.formData.create_time = `${val[0]},${val[1]}`;
        } else {
          this.pay_at = [];
          this.formData.create_time = '';
        }
    },
    payChange1(val) {
        if (val) {
          this.formData.operator_at = `${val[0]},${val[1]}`;
        } else {
          this.pay_at1 = [];
          this.formData.operator_at = '';
        }
    },
    hqlist() {
      this.loading = true;
      this.$api.general.aftersalecomment(this.formData).then((res) => {
        // console.log(res, "11111111111111");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
    /**
     * 初始化
     */
    onInitData() {
      this.hqlist();
    },
    onHandleSearch() {
      this.formData.page = 1;
      this.hqlist();
    },
    /**
     * 刷新重置
     */
    Refresh() {
      Object.assign(this.$data.formData, this.$options.data().formData);
      this.pay_at = [];
      this.pay_at1 = [];
      this.hqlist();
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.hqlist();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.hqlist();
    },

    //导出
    onHandleExport() {
      this.$api.general.bicontractrestartexport(this.formData).then((res) => {
        console.log(res);
        this.goExportCenter();
      });
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
  },
};
</script>
  <style lang="scss">
.evaluation {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }
    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
  